import React from "react";

import { SelectInput } from "react-admin";

import { CollectionPartnerStatus, CollectionPartnerType } from "../../components/collections/types";
import {
  baseMultilineTextInputFilter
} from "../../components/filter/base-multiline-text-input-filter";
import { countriesFilter } from "../../components/filter/customer-filters";
import { enumSelectFilter } from "../../components/filter/enum-select-filter";
import { buildLabel, CustomFilterIcon } from "../../components/filter/filter-utils";
import { useNumberRangeFilters } from "../../components/filter/use-number-range-filters";
import { useStockItemFilter } from "../../components/stock-item/use-stock-item-filter";
import { CapacityUsage } from "../../components/stock/types";
import {
  useCollectionAreaFilter,
  useCollectionCityFilter, useCollectionCodeFilter,
  useCollectionProvinceFilter
} from "../collection-partners/use-collections-filters";

const useStockBalanceFilter = () => {
  return [
    useStockItemFilter(),
    ...useNumberRangeFilters("stockBalanceGOE", "Stock Balance (>=)", "stockBalanceLOE", "Stock Balance (<=)")
  ];
};

const capacityUsageFilter = () => {
  const choices = Object.entries(CapacityUsage)
    .map(([key, value]) => ({ id: key, name: value }));

  return <SelectInput
    source={"capacityUsage"}
    key={"capacityUsage"}
    label={buildLabel({ label: "Capacity Usage", Icon: CustomFilterIcon })}
    variant="filled"
    choices={choices}
  />;
};

export const useStockFilters = () => {

  return [
    useCollectionCodeFilter(),
    useCollectionProvinceFilter(),
    useCollectionCityFilter(),
    useCollectionAreaFilter(),
    baseMultilineTextInputFilter({
      source: "names",
      label: buildLabel({ label: "Name", Icon: CustomFilterIcon })
    }),
    countriesFilter(true, CustomFilterIcon),
    enumSelectFilter({
      source: "types",
      enumObj: CollectionPartnerType,
      label: buildLabel({ label: "Type", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    enumSelectFilter({
      source: "statuses",
      enumObj: CollectionPartnerStatus,
      label: buildLabel({ label: "Status", Icon: CustomFilterIcon }),
      alwaysOn: true
    }),
    ...useStockBalanceFilter(),
    capacityUsageFilter()
  ];
};
