import React, { ReactElement } from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import { DatagridConfigurable, FunctionField, List, ReferenceField } from "react-admin";
import { Link } from "react-router-dom";

import {
  CapacityUnit,
  CollectionPartner,
  CollectionPartnerStatus,
  CollectionPartnerType
} from "../../components/collections/types";
import { PresetListActions } from "../../components/list/actions/PresetListActions";
import { DefaultPagination } from "../../components/pagination/DefaultPagination";
import {
  StockItemType,
  CollectionPartnerStockBalance,
  StockBalance
} from "../../components/stock/types";
import { Resources } from "../../resources";
import {
  countryNameRenderer,
  EnumChipColors,
  enumChipRenderer,
  enumRenderer,
  geolocationRenderer
} from "../../utils/field-renderers";
import { useStockFilters } from "./filters";
import { capacityUsageRender } from "./renders";

const Okay: React.FC = () => <CheckCircleIcon color="success" fontSize="small"/>;
const ErrorWarning: React.FC = () => <ReportProblemIcon color="warning" fontSize="small"/>;
const ErrorCritical: React.FC = () => <ErrorIcon color="error" fontSize="small"/>;

const statusChipColors: Record<keyof typeof CollectionPartnerStatus, EnumChipColors> = {
  ACTIVE: "success",
  INACTIVE: "secondary"
};

const findStockItemQuantity = (record: CollectionPartnerStockBalance, itemType:StockItemType, itemName: string) => {
  const stockBalance =
      record.stockBalances?.[itemType.toUpperCase()]?.find((stock) => stock.name === itemName);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Grid container justifyContent="flex-end" sx={{ minWidth: "30px", fontWeight: "600" }}>
        <Grid>{(stockBalance?.quantity || 0).toLocaleString()}</Grid>
      </Grid>
      {stockBalanceIcon(stockBalance)}
    </Box>
  );
};

const displayCapacity = (record: CollectionPartnerStockBalance) => {
  const capacity = record.capacity;
  if (!capacity) {
    return;
  }
  const unit = capacity > 1000 ? CapacityUnit.TON : CapacityUnit.KG;
  const displayCapacity = capacity > 1000 ? (capacity / 1000).toFixed(2) : capacity;

  return `${displayCapacity} ${unit}`;
};

const renderTooltipLabel = (label: string | ReactElement, tooltip: string | ReactElement) => {
  return (
    <Tooltip title={tooltip} placement="top">
      <Box>{label}</Box>
    </Tooltip>
  );
};

const calculateTotalStockTonnage = (record: CollectionPartnerStockBalance) => {
  const stockBalance =
      record.stockBalances?.["PELLETS"]?.reduce((total: number, balance: StockBalance) => {
        return total + balance.quantity * balance.weight;
      }, 0) || 0;

  return (
    <Grid sx={{ fontWeight: "600" }} justifyContent="flex-end" container>
      <Grid item>
        {Math.round(stockBalance / 1000).toLocaleString()}
      </Grid>
    </Grid>
  );
};

export const stockBalanceIcon = (stockBalance: StockBalance | undefined) => {
  if (stockBalance) {
    if (stockBalance.quantity <= stockBalance.criticalThreshold) {
      return (<ErrorCritical/>);
    } else if (stockBalance.quantity <= stockBalance.warningThreshold) {
      return (<ErrorWarning/>);
    }
  }
  return (<Okay/>);
};

export const StockList: React.FC = () => {
  return (
    <List filters={useStockFilters()}
      filterDefaultValues={{ statuses: "ACTIVE" }}
      pagination={<DefaultPagination/>}
      perPage={25}
      actions={
        <PresetListActions exportingEnabled={false} selectColumnsEnabled/>
      }
      exporter={false}
      hasCreate={false}>
      <DatagridConfigurable bulkActionButtons={false} omit={["address", "phoneNumber", "geolocation", "Pellets 15kg"]}>
        <ReferenceField
          label="Code"
          reference={Resources.CollectionPartners}
          source="id"
          link={false}
          sortable={false}
        >
          <FunctionField
            render={(record: CollectionPartner) => (
              <Link to={`/stock-balances/${record.id}/show`}>
                {record.code}
              </Link>)}/>
        </ReferenceField>
        <ReferenceField
          label="Name"
          reference={Resources.CollectionPartners}
          source="id"
          link={false}
          sortable={false}
        >
          <FunctionField
            render={(record: CollectionPartner) => record.name}/>
        </ReferenceField>

        <ReferenceField
          label="Type"
          reference={Resources.CollectionPartners}
          source="id"
          link={false}
          sortable={false}
        >
          <FunctionField
            render={(record: CollectionPartner) => enumRenderer(record.type, CollectionPartnerType)}/>
        </ReferenceField>
        <ReferenceField
          label="Country"
          reference={Resources.CollectionPartners}
          source="id"
          link={false}
          sortable={false}
        >
          <FunctionField
            render={(collectionPartner: CollectionPartner) => countryNameRenderer(collectionPartner)} />
        </ReferenceField>
        <FunctionField
          source="address"
          sortable={false}
          render={() =>
            <ReferenceField
              label="Address"
              reference={Resources.CollectionPartners}
              source="id"
              link={false}
            >
              <FunctionField
                render={(record: CollectionPartner) => record.address}/>
            </ReferenceField>
          }
        />
        <FunctionField
          source="phoneNumber"
          sortable={false}
          render={() =>
            <ReferenceField
              label="Phone Number"
              reference={Resources.CollectionPartners}
              source="id"
              link={false}
            >
              <FunctionField
                render={(record: CollectionPartner) => record.phoneNumber}/>
            </ReferenceField>
          }
        />
        <FunctionField
          source="geolocation"
          sortable={false}
          render={() =>
            <ReferenceField
              label="Geolocation"
              reference={Resources.CollectionPartners}
              source="id"
              link={false}
            >
              <FunctionField
                render={(record: CollectionPartner) => geolocationRenderer(record)}/>
            </ReferenceField>
          }
        />
        <FunctionField label="Pellets 5kg"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.PELLETS, "Pellets 5kg")}
          sortBy="Pellets 5kg"/>
        <FunctionField label="Pellets 15kg"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.PELLETS, "Pellets 15kg")}
          source="Pellets 15kg" sortBy="Pellets 15kg"/>
        <FunctionField label="Pellets 20kg"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.PELLETS, "Pellets 20kg")}
          sortBy="Pellets 20kg"/>
        <FunctionField label="Pellets 30kg"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.PELLETS, "Pellets 30kg")}
          sortBy="Pellets 30kg"/>
        <FunctionField label="Pellets 50kg"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.PELLETS, "Pellets 50kg")}
          sortBy="Pellets 50kg"/>
        <FunctionField label="Stock Tonnage"
          render={(record: CollectionPartnerStockBalance) => calculateTotalStockTonnage(record)}
          sortable={false}/>
        <FunctionField label="Capacity"
          render={(record: CollectionPartnerStockBalance) => displayCapacity(record)}
        />
        <FunctionField
          source = "capacityUsage"
          label={renderTooltipLabel("Capacity Usage",
            <Box>
              This represents the Capacity Usage,
              which is determined by the percentage of storage space used in arrears..<br/>
              <strong>&lt;= 0%</strong> - Empty<br/>
              <strong>&gt; 0% and &lt;= 45%</strong> - Below Minimum<br/>
              <strong>&gt; 45% and &lt;= 55%</strong> - Average<br/>
              <strong>&gt; 55% and &lt;= 90%</strong> - Above Average<br/>
              <strong>&gt; 90 %</strong> - Full
            </Box>
          )}
          render={(record: CollectionPartnerStockBalance) => capacityUsageRender(record)}
        />
        <FunctionField label="Stove"
          render={(record: CollectionPartnerStockBalance) => findStockItemQuantity(record, StockItemType.STOVE, "Stove")}
          sortBy="Stove"/>
        <FunctionField
          source="status"
          sortable={false}
          render={() =>
            <ReferenceField
              label="Status"
              reference={Resources.CollectionPartners}
              source="id"
              link={false}
            >
              <FunctionField
                render={(record: CollectionPartner) =>
                  enumChipRenderer(record.status, CollectionPartnerStatus, statusChipColors)}/>
            </ReferenceField>
          }
        />
      </DatagridConfigurable>
    </List>
  );
};