export type CollectionPartnerStockBalance = {
  id: number,
  capacity: number,
  capacityUsage? : CapacityUsage,
  stockBalances: Record<string, Array<StockBalance>>
};

export type StockBalance = {
  stockItemId: number,
  name: string,
  quantity: number,
  weight: number,
  criticalThreshold: number,
  warningThreshold: number
};

export enum StockItemType {
  PELLETS = "Pellets",
  STOVE = "Stove",
  ACCESSORY = "Accessory"
}

export enum CapacityUsage {
  EMPTY = "Empty",
  BELOW_MINIMUM = "Below Minimum",
  AVERAGE = "Average",
  ABOVE_AVERAGE = "Above Average",
  FULL = "Full"
}

export const CapacityUsageColors: Record<keyof typeof CapacityUsage, string> = {
  EMPTY: "#8B0000", // Deep Red
  BELOW_MINIMUM: "red", // Light Red
  AVERAGE: "#ddbf06", // Dark Yellow
  ABOVE_AVERAGE: "#90EE90", // Light Green
  FULL: "green" // Deep Green
};