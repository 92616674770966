import React from "react";

import { isNil } from "lodash-es";

import {
  CapacityUsage,
  CapacityUsageColors,
  CollectionPartnerStockBalance
} from "../../components/stock/types";
import { CustomChip, enumRenderer } from "../../utils/field-renderers";

export const capacityUsageRender = (record: CollectionPartnerStockBalance) => {
  if (isNil(record.capacityUsage)) {
    return <></>;
  }
  const capacityUsage = record.capacityUsage;
  const color = CapacityUsageColors[capacityUsage.toUpperCase() as keyof typeof CapacityUsageColors];
  const label = enumRenderer(capacityUsage, CapacityUsage);
  if (!label) return (<></>);
  return (
    <CustomChip
      textColor={color}
      label={label}
    />
  );
};