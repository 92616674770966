import React from "react";

import { isNil } from "lodash-es";

import { CustomChip, enumRenderer } from "../../utils/field-renderers";
import {
  ConnectivityLevel,
  ConnectivityLevelColor,
  PARCategories, PARCategoriesColor,
  ProfilePerformance
} from "./types";

export const connectivityLevelRender = (record: ProfilePerformance) => {
  if (isNil(record.stoveId) || isNil(record.connectivityLevel)) {
    return <></>;
  }

  const level = record.connectivityLevel;
  const color = ConnectivityLevelColor[level.toUpperCase() as keyof typeof ConnectivityLevelColor];
  const label = enumRenderer(level, ConnectivityLevel);
  if (!label) return (<></>);
  return (
    <CustomChip
      textColor={color}
      label={label}
    />
  );
};

export const parCategoryRender = (record: ProfilePerformance) => {
  if (isNil(record.customerId) || isNil(record.parCategory)) {
    return <></>;
  }
  const parCategory = record.parCategory;
  const color = PARCategoriesColor[parCategory.toUpperCase() as keyof typeof PARCategoriesColor];
  const label = enumRenderer(parCategory, PARCategories);
  if (!label) return (<></>);
  return (
    <CustomChip
      textColor={color}
      label={label}
    />
  );
};